import React from 'react'
import { Container, Image } from 'react-bootstrap';
/* import {ReactComponent as AppStore} from "../../assets/svg/Download_on_the_App_Store_Badge_ESMX_RGB_blk_100217.svg";  */
import PlayStore from '../../assets/png/google-play.png';
import AppScreens from '../../assets/png/pantallasBlur.png';
import './Banner.scss';

const Banner = () => {
  return (
    <div className='banner' id='inicio'>
      <Container>
        <div className="descripcion">
          <h1>Acceso Inteligente, Control Preciso</h1>
        <p>Access-Go es la solución ideal para gestionar el tiempo de estancia de tus clientes con pulseras NFC, optimizando la experiencia y eficiencia de tu establecimiento.</p>   
        <div className="container_logos">
         <a href="https://play.google.com/store/apps/details?id=com.mx.accessgo&pcampaignid=web_share"><img src={PlayStore} alt="" /></a>
        {/* <a href="#"><AppStore/></a>    */}  
        </div>
        </div>
       <div className="image_fondo">
            <Image src={AppScreens} alt="" width={100}/>
       </div>
      </Container>
    </div>
  )
}

export default Banner
