import React from 'react'
import './DeleteAccount.scss';

const DeleteAccount = () => {
    return (
        <div className="eliminar-datos-usuario">
          <h1>Eliminación de Datos de la Cuenta de Empresa</h1>
          
          <p>
            En Access Go, nos tomamos muy en serio la privacidad y la seguridad de tu información personal. A continuación, te explicamos cómo puedes eliminar los datos de tu cuenta de empresa en nuestra plataforma:
          </p>
          
          <ol>
            <li>
              <strong>Contactar al proveedor:</strong> La eliminación de datos solo se puede realizar para cuentas de empresa. Para iniciar el proceso, debes contactar al proveedor con el que contrataste el servicio.
            </li>
            <li>
              <strong>Solicitar la baja de tu empresa:</strong> Alternativamente, puedes contactar directamente a Access Go y solicitar la baja de tu empresa en la plataforma. Es importante que indiques claramente que deseas eliminar tu cuenta.
            </li>
            <li>
              <strong>Proceso de eliminación:</strong> Una vez que hayas realizado la solicitud, procederemos a eliminar todos los datos asociados a tu empresa. Esto incluye:
              <ul>
                <li>Eventos realizados en tus instalaciones.</li>
                <li>Datos de los usuarios que visitaron tus instalaciones.</li>
                <li>Datos de los empleados que laboran o laboraron en tus instalaciones.</li>
              </ul>
            </li>
          </ol>
    
          <h2>Para más información:</h2>
          <p>
            Si tienes alguna pregunta o inquietud sobre la eliminación de tus datos, no dudes en contactarnos a través de nuestro correo electrónico: <a href="mailto:contacto@accessgo.com.mx">contacto@accessgo.com.mx</a>.
          </p>
        </div>
      );
    };
    

export default DeleteAccount

