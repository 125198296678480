import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy__container">
        <h1 className="privacy-policy__title">Política de Privacidad de Access Go</h1>
        <p className="privacy-policy__date">Última actualización: miércoles 26 de octubre del 2024</p>
        <p className="privacy-policy__text">
        En Access Go, nos comprometemos a proteger la privacidad de nuestros usuarios. Esta Política de Privacidad explica cómo recopilamos, utilizamos y almacenamos la información personal proporcionada por clientes, trabajadores y empresas que utilizan nuestra aplicación.   
        </p>

        <section className="privacy-policy__section">
          <h2 className="privacy-policy__section-title">1. Información que Recopilamos</h2>
          <h3 className="privacy-policy__subsection-title">Para Clientes:</h3>
          <p className="privacy-policy__text">
            Recopilamos la siguiente información personal de los clientes que utilizan nuestras aplicaciones:
            <ul>
              <li>Nombre completo</li>
              <li>Número de teléfono (utilizado para funcionalidades dentro de la aplicación)</li>
              <li>Firma autógrafa</li>
              <li>Fotografía</li>
            </ul>
          </p>
          <p className="privacy-policy__text">
            Esta información es utilizada para proteger a las empresas que utilizan nuestro software en caso de que ocurra un accidente en sus instalaciones. Estos datos complementan la carta responsiva del usuario que debe ser proporcionada.
          </p>
        </section>

        <section className="privacy-policy__section">
          <h3 className="privacy-policy__subsection-title">Para Trabajadores:</h3>
          <p className="privacy-policy__text">
            Recopilamos la siguiente información de los trabajadores que utilizan la aplicación:
            <ul>
              <li>Nombre completo</li>
              <li>Fotografía</li>
              <li>Número de teléfono (opcional)</li>
              <li>Correo electrónico (opcional)</li>
            </ul>
          </p>
          <p className="privacy-policy__text">
            Esta información se almacena para que la empresa que utilice la aplicación pueda verificar que el cobro por el tiempo de permanencia de los clientes en las instalaciones se esté realizando de manera correcta y para identificar quién realiza dicho cobro.
          </p>
        </section>

        <section className="privacy-policy__section">
          <h3 className="privacy-policy__subsection-title">Para Empresas:</h3>
          <p className="privacy-policy__text">
            Recopilamos y almacenamos los siguientes datos de las empresas que utilizan la aplicación:
            <ul>
              <li>Nombre de la empresa</li>
              <li>Logotipo</li>
              <li>Lista de empleados</li>
              <li>Registro de actividades</li>
            </ul>
          </p>
        </section>
        <section className="privacy-policy__section">
          <h2 className="privacy-policy__section-title">2. Uso de la Información</h2>
          <ul>
              <li><b>Clientes:</b> La información proporcionada se utiliza con fines de protección legal y para garantizar la seguridad en caso de accidentes dentro de las instalaciones de las empresas que utilizan nuestra aplicación.</li>
              <li><b>Trabajadores:</b> La información de los trabajadores permite a las empresas verificar que el cobro por el tiempo de permanencia de los clientes en las instalaciones sea correcto y para identificar a la persona responsable del cobro.</li>
              <li><b>Empresas:</b> Los datos de las empresas se utilizan para la administración interna de sus cuentas, empleados y actividades dentro de la aplicación.</li>
            </ul>
        </section>
        <section className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">3. Conservación y Eliminación de Datos</h2>   
        <ul>
              <li><b>Clientes:</b> La información proporcionada por los clientes (nombre, firma autógrafa, fotografía) se mantendrá almacenada como medida de protección para las empresas que utilizan nuestro software. Estos datos solo se eliminarán en caso de que la empresa con la que esté relacionado el cliente se dé de baja de la aplicación.</li>
              <li><b>Trabajadores:</b> Los datos de los trabajadores serán eliminados si la empresa con la que estuvieron involucrados se da de baja de la aplicación.</li>
              <li><b>Empresas:</b> Si una empresa decide darse de baja del sistema, todos los datos asociados a ella, incluyendo actividades, empleados y cualquier otro dato relacionado, serán completamente eliminados, así como los datos de los usuarios que visitaron sus instalaciones.</li>
            </ul>
        </section>
        {/* Más secciones... */}

        <section className="privacy-policy__section">
          <h2 className="privacy-policy__section-title">4. Seguridad de los Datos</h2>
          <p className="privacy-policy__text">
            En <strong>Access Go</strong>, tomamos medidas técnicas y organizativas para proteger la información personal contra accesos no autorizados, alteración, divulgación o destrucción. Sin embargo, ningún sistema es completamente seguro, por lo que no podemos garantizar la seguridad absoluta de la información almacenada.
          </p>
        </section>

        <section className="privacy-policy__section">
          <h2 className="privacy-policy__section-title">6. Contacto</h2>
          <p className="privacy-policy__text">
            Si tienes preguntas o inquietudes sobre esta Política de Privacidad, puedes ponerte en contacto con nosotros:
          </p>
          <p className="privacy-policy__text">
            <strong>Access Go</strong><br />
            Correo electrónico: <a href="mailto:contacto@accessgo.com.mx">contacto@accessgo.com.mx</a><br />
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
