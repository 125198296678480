import React from 'react';
import './Principal.scss';
import Banner from '../Banner/Banner';
import Description from '../Description';
import Ventajas from '../Ventajas/Ventajas';
import DownloadSection from '../DownloadSection/DownloadSection';
import CasosDeUso from '../CasosDeUso/CasosDeUso';
import Soporte from '../Soporte/Soporte';

export default function Principal() {
    return (
        <div className='web_principal'>
            <Banner/>
            <Description />
            <Ventajas/>
            <CasosDeUso/>
            <DownloadSection/>
            <Soporte/>
        </div>
    )
}
