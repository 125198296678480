
export const resetPasswordLocate = async(token, data) => {


    const url = `${process.env.REACT_APP_BACKEND_URL}/api/user/resetPassword/${token}`;
    
    const resp = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    return await resp.json();

}




export const consultaToken = async(token, method = 'GET', data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/renew`;


    if (method === 'GET') {
        const resp = await fetch(url, {
            headers: {
                'x-auth-token': token
            }
        });
        return await resp.json();
    } else {
        const resp = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token
            },
            body: JSON.stringify(data)
        })
        return await resp.json();
    }

}

