import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'; // Cambiado a useHistory
import { consultaToken } from '../../api/reset';

const VerifyToken = () => {
    const history = useHistory(); // Cambiado a useHistory
    const { token } = useParams();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                // Realiza una solicitud para verificar el token en el servidor
                const response = await consultaToken(token);

                if (response.ok === false) {
                    // Si hay un error, redirige a una página de error
                    history.push(`/verify/error?message=${response.msg}`);
                } else {
                    // Si la verificación es exitosa, redirige a la página correspondiente
                    history.push(`/resetpassword/${response.token}`);
                }
            } catch (error) {
                // Maneja errores de la solicitud
                console.error('Error al verificar el token', error);
                // Redirige a una página de error
                history.push('/verify/error?message=Error al verificar el token');
            }
        };

        if (token) {
            verifyToken();
        }
    }, [token, history]); // Cambiado router por history

    return <div>Verificando Token...</div>;
}

export default VerifyToken;
