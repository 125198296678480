import React from "react";
import "./Soporte.scss";
import { Container, Col, Row, Image } from "react-bootstrap";
import Movil from "../../assets/png/soporte.png";
/* import { ReactComponent as Phone } from "../../assets/svg/circulo-de-telefono.svg";
import { ReactComponent as WhatsApp } from "../../assets/svg/whatsapp.svg";
import { ReactComponent as Mail } from "../../assets/svg/sobre.svg"; */

const Soporte = () => {
  return (
    <div className="soporte" id="contacto">
      <Container>
        <Row>
          <Col md={6} sm={12} xl={7} className="points">
            <h1>
              Habla con Nuestro Equipo de Ventas y Activa Access-Go en Tu
              Negocio
            </h1>
            <p>
              ¿Interesado en integrar <b>Access-Go </b> en tu empresa? Nuestro
              equipo de ventas está listo para ayudarte a dar de alta tu negocio
              y guiarte en cada paso del proceso. Con Access-Go, podrás
              optimizar el control de estancias, gestionar tiempos de forma
              precisa y mejorar la experiencia de tus clientes. Contáctanos hoy
              mismo y descubre cómo Access-Go puede marcar la diferencia en la
              administración y seguridad de tu establecimiento.
            </p>

            <p>Contáctanos para Más Información.</p>
            <div className="container_img">
              {/* Enlace para hacer una llamada */}
              {/* <a href="tel:+525539352920">
                <Phone />
              </a> */}

              {/* Enlace para enviar un mensaje de WhatsApp */}
             {/*  <a href="https://wa.me/525539352920?text=Hola,%20me%20gustaría%20recibir%20más%20información%20sobre%20los%20requisitos%20y%20beneficios%20para%20obtener%20una%20cuenta%20en%20Access-Go.">
                <WhatsApp />
              </a> */}

              {/* Enlace para enviar un correo electrónico */}
              {/* <a href="mailto:soporte@accessgo.com.mx?subject=Información%20sobre%20cuenta%20Access-Go">
                <Mail />
              </a> */}
            </div>
          </Col>
          <Col md={6} sm={12} xl={5} className="image_container">
            <Image src={Movil} width={100} className="img_phone" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Soporte;
