import React from 'react';
import routes from './routes/routes'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <Router>
      <Switch>
{routes.map((route, index) =>(
  <RouteWithSubRoutes key={index}{...route} />
))}
      </Switch>
    </Router>
    
  );
}

function RouteWithSubRoutes(route){
  return (
    <>
    <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
         <Route 
  path={route.path}
  exact={route.exact}
  render={props => <route.component routes={route.routes}{...props}/>}
  />
    </>
 
  );
}


export default App;
