import React from 'react';
import './Ventajas.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Check from '../../Components/Check/Check';
import Movil from "../../assets/png/eventoblur.png";

const Ventajas = () => {
  return (
    <div className="ventajas" id='falta'>
      <Container>
        <Row>
        <Col md={5} sm={12} xl={5} className="image_container">
            <Image src={Movil} width={100} className="img_phone" />
          </Col>
          <Col md={7} sm={12} xl={7} className="points">
            <h1>¿Por Qué Elegir Access-Go?</h1>
            <Check
              title={"Prevención de Fraudes:"}
              description={
                "Asegura que los clientes paguen únicamente por el tiempo que realmente utilizan, eliminando el riesgo de pérdidas por evasión."
              }
            />
            <Check
              title={"Optimización del Servicio:"}
              description={
                "Facilita un flujo eficiente de personas en el establecimiento, mejorando la atención al cliente y reduciendo tiempos de espera."
              }
            />
            <Check
              title={"Mejor Experiencia para el Cliente"}
              description={
                "Ofrece notificaciones anticipadas y opciones de recarga de tiempo, brindando comodidad y flexibilidad en la visita."
              }
            />
            <Check
              title={"Aumento en la Eficiencia"}
              description={
                "Permite una gestión más efectiva del espacio y los recursos, maximizando la capacidad del establecimiento y mejorando la operativa general."
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Ventajas
