import React from "react";
import "./Footer.scss";
import Logo from "../../assets/png/accessCompleto.png";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <div className="footer">
      <Container>
        <div className="top">
          <Row>
            <Col md={5} sm={12} xl={5} className="info">
              <Image src={Logo} width={100} />
              <p>
                La aplicación vincula pulseras NFC con el sistema, permitiendo
                que cada cliente sea identificado y su tiempo de permanencia sea
                monitoreado en tiempo real. Esto ofrece múltiples ventajas tanto
                para los usuarios como para los administradores del
                establecimiento.
              </p>
            </Col>
            <Col md={3} sm={12} xl={3} className="atajos">
              <p className="title">Enlaces rápidos</p>
              <Link
                className={`nav-item`}
                activeClass="active"
                to="inicio"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Inicio
              </Link>
              <Link
                className={`nav-item`}
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Funcionalidades
              </Link>
              <Link
                className={`nav-item`}
                activeClass="active"
                to="falta"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                ¿Por qué elegirnos?
              </Link>
              <Link
                className={`nav-item`}
                activeClass="active"
                to="funcion"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Usos
              </Link>
              <Link
                className={`nav-item`}      
                activeClass="active"
                to="descarga"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Descargar
              </Link>
              <Link
                className={`nav-item`}
                activeClass="active"
                to="contacto"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Contacto
              </Link>
            </Col>
            <Col md={4} sm={12} xl={4} className="contacto">
            <p className="title">Contacto</p>
            </Col>
          </Row>
        </div>
        <div className="button">
        <p>&copy; {(new Date().getFullYear())} Access-go todos los derechos reservados </p>  
        </div>
      </Container>
    </div>
  );
}
