import React from 'react';
import "./DownloadSection.scss";
import { Container, Row, Col } from 'react-bootstrap';
import PlayStore from '../../assets/png/google-play.png';
/* import {ReactComponent as AppStore} from "../../assets/svg/Download_on_the_App_Store_Badge_ESMX_RGB_blk_100217.svg";  */

const DownloadSection = () => {
  return (
    <div className='downloadSection' id='descarga'>
        <Container>
            <Row>
            <Col md={12} sm={12} xl={7} className="data_info">
            <h1>Descarga Access-Go y Optimiza tu Negocio</h1>
            <p>Accede a todas las ventajas de Access-Go descargando la aplicación en tu dispositivo. Simplifica el control de estancias, recibe notificaciones automáticas y gestiona a tu equipo desde una interfaz intuitiva. ¡Disponible en las tiendas de apps para que tu negocio esté siempre bajo control!</p>
            </Col>
            <Col md={12} sm={12} xl={5} className="icons">
            <a href="https://play.google.com/store/apps/details?id=com.mx.accessgo&pcampaignid=web_share"><img src={PlayStore} alt="" /></a>
            {/* <a href="#"><AppStore/></a>   */}  
            </Col>
            </Row>
      
        </Container>
    </div>
  )
}

export default DownloadSection
