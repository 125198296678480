import React, { useState, useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import { Link } from "react-scroll"; 
import Logo from "../../assets/png/accessLetreto.png";
import LogoAzul from "../../assets/png/accessGoLogoAzul.png";
import { ReactComponent as Menu } from "../../assets/svg/barras-escalonadas.svg"; 
import { ReactComponent as Close } from "../../assets/svg/cruz.svg"; 
import './Navbar.scss';

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [active, setActive] = useState("home");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // ScrollSpy setup to track active link
    const handleScroll = () => {
      const sections = ["home", "falta", "funcion", "descarga", "footer"];
      const scrollPosition = window.scrollY;

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const { offsetTop, clientHeight } = element;
          if (scrollPosition >= offsetTop - 70 && scrollPosition < offsetTop + clientHeight) {
            setActive(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="nav">
      <Container className={`navbar_top ${open ? 'open' : ''}`}>
        <Image src={Logo} />

        {isMobile ? (
          <div className="button_container">
            <Menu onClick={() => setOpen(true)} />
          </div>
        ) : (
          <ul className="barra">
            <Link
              className={`nav-item ${active === 'inicio' ? 'active' : ''}`}
              activeClass="active"
              to="inicio"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Inicio
            </Link>
            <Link
              className={`nav-item ${active === 'home' ? 'active' : ''}`}
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Funcionalidades
            </Link>
            <Link
              className={`nav-item ${active === 'falta' ? 'active' : ''}`}
              activeClass="active"
              to="falta"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              ¿Por qué elegirnos?
            </Link>
            <Link
              className={`nav-item ${active === 'funcion' ? 'active' : ''}`}
              activeClass="active"
              to="funcion"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Usos
            </Link>
            <Link
              className={`nav-item ${active === 'descarga' ? 'active' : ''}`}
              activeClass="active"
              to="descarga"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Descargar
            </Link>
            <Link
              className={`nav-item ${active === 'contacto' ? 'active' : ''}`}
              activeClass="active"
              to="contacto"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contacto
            </Link>
          </ul>
        )}
      </Container>
      {isMobile && open && (
        <div className={`barra dropdown ${open ? 'open' : 'close'}`}>
          <Close onClick={() => setOpen(false)} className="close-button" />
          <Image src={LogoAzul} />
          <ul>
          <Link
              className={`nav-item ${active === 'inicio' ? 'active' : ''}`}
              activeClass="active"
              to="inicio"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Inicio
            </Link>
            <Link
              className={`nav-item ${active === 'home' ? 'active' : ''}`}
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Funcionalidades
            </Link>
            <Link
              className={`nav-item ${active === 'falta' ? 'active' : ''}`}
              activeClass="active"
              to="falta"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
             ¿Por qué elegirnos?
            </Link>
            <Link
              className={`nav-item ${active === 'funcion' ? 'active' : ''}`}
              activeClass="active"
              to="funcion"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Usos
            </Link>
            <Link
              className={`nav-item ${active === 'descarga' ? 'active' : ''}`}
              activeClass="active"
              to="descarga"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Descargar
            </Link>
            <Link
              className={`nav-item ${active === 'contacto' ? 'active' : ''}`}
              activeClass="active"
              to="contacto"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contacto
            </Link>
          </ul>
        </div>
      )}
    </div>
  );
}
