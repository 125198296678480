//Layout
import Layout from '../layout/layout';
import LayoutServicios from '../layout/layoutServicios';

//Paginas
import Home from '../pages/Principal';
import DeleteAccount from '../pages/DeleteAccount';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import ResponsiveLetter from '../pages/ResponsiveLetter';
import Error404 from '../pages/Error404';
import VerifyToken from '../pages/VerifyToken';
import VerifyError from '../pages/VerifyError';
import ResetPassword from '../pages/ResetPassword';

const routes = [
    {
        path: "/verify",
        component: LayoutServicios,
        exact: false,
        routes: [
            {
                path: "/verify/error",
                component: VerifyError,
                exact: true
            },
            {
                path: "/verify/:token",
                component: VerifyToken,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/resetpassword",
        component: LayoutServicios,
        exact: false,
        routes: [{
                path: "/resetpassword/:token",
                component: ResetPassword,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/responsiveLetter",
        component: LayoutServicios,
        exact: false,
        routes: [
            {
                path: "/responsiveLetter",
                component: ResponsiveLetter,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/privacyPolicy",
        component: LayoutServicios,
        exact: false,
        routes: [
            
            {
                path: "/privacyPolicy",
                component: PrivacyPolicy,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/deleteAccount",
        component: LayoutServicios,
        exact: false,
        routes: [{
                path: "/deleteAccount",
                component: DeleteAccount,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
    path: "/",
    component: Layout,
    exact: false,
    routes: [{
            path: "/",
            component: Home,
            exact: true
        },
        {
            component: Error404
        }
    ]
}];

export default routes;