import React from "react";
import "./Description.scss";
import { Container, Col, Row, Image } from "react-bootstrap";
import Check from "../../Components/Check/Check";
import Movil from "../../assets/png/celblurlimit.png";

const Description = () => {  
  return (
    <div className="description" id="home">
      <Container>
        <Row>
          <Col md={7} sm={12} xl={7} className="points">
            <h1>Funcionalidades Clave de Access-Go</h1>
            <Check
              title={"Interfaz intuitiva:"}
              description={
                "Access-Go tiene un diseño amigable y fácil de navegar, lo que permite a personal y administradores usar la aplicación sin complicaciones."
              }
            />
            <Check
              title={"Control de tiempo preciso:"}
              description={
                "Registra manualmente el ingreso de pulseras NFC según el número de acompañantes y escanea al salir para guardar el tiempo total de permanencia."
              }
            />
            <Check
              title={"Gestión de Empleados Eficiente"}
              description={
                "Los administradores pueden cargar a sus empleados, permitiéndoles gestionar tareas, crear eventos y aumentar tiempos de estancia de forma autónoma."
              }
            />
            <Check
              title={"Avisos Previos al Fin de Estancia"}
              description={
                "Envía notificaciones por WhatsApp cinco minutos antes de que termine el tiempo de estancia, ayudando a los usuarios a decidir si extender su visita."
              }
            />
            <Check
              title={"Firma Digital y Registro Fotográfico"}
              description={
                "Los clientes firman una carta responsiva y se toma una fotografía al ingresar, que se guarda en el historial de eventos y puede descargarse en PDF."
              }
            />
          </Col>
          <Col md={5} sm={12} xl={5} className="image_container">
            <Image src={Movil} width={100} className="img_phone" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Description;
