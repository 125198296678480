import React from 'react'
import "./CasosDeUso.scss";
import { Container, Col, Row, Image } from "react-bootstrap";
import Check from "../../Components/Check/Check";
import Movil from "../../assets/png/eventosBlur.png";

const CasosDeUso = () => {
  return (
    <div className='usos' id='funcion'>
       <Container>
        <Row>
          <Col md={7} sm={12} xl={7} className="points">
            <h1>Usos Versátiles para Diversos Establecimientos</h1>
            <p className='description_data'>Aunque Access-Go fue creada para monitorear el tiempo de estancia en parques, es una herramienta flexible que se adapta a distintos tipos de negocios. Aprovecha su capacidad para controlar el uso de pulseras NFC y lleva un conteo claro de los clientes en tu establecimiento, mejorando la organización y eficiencia. Algunos ejemplos de uso incluyen:</p>
            <Check
              title={"Parques y Centros de Atracciones:"}
              description={
                "Gestiona el tiempo de estancia de visitantes y optimiza la rotación de personas."
              }
            />
            <Check
              title={"Áreas de Co-Working:"}
              description={
                "Lleva un registro preciso de las horas de uso para optimizar el espacio."
              }
            />
            <Check
              title={"Gimnasios y Centros Deportivos:"}
              description={
                "Supervisa las pulseras NFC activas para controlar la capacidad en áreas clave."
              }
            />
            <Check
              title={"Spas y Centros de Bienestar:"}
              description={
                "Administra cabinas y áreas comunes según el tiempo de uso de cada cliente."
              }
            />
            <Check
              title={"Eventos y Festivales:"}
              description={
                "Controla el tiempo de los asistentes y mejora la gestión en áreas específicas."
              }
            />
          </Col>
          <Col md={5} sm={12} xl={5} className="image_container">
            <Image src={Movil} width={100} className="img_phone" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CasosDeUso
