import React from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const VerifyError = () => {
    const location = useLocation();
    // Crear un objeto para buscar parámetros en el query string
    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get('message'); 

    return (
        <Alert variant="danger">
            {message || "An error occurred!"}  {/* Fallback por si el mensaje es nulo */}
        </Alert>
    );
}

export default VerifyError;
