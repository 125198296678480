import React from 'react';
import './Check.scss';
import CheckImg from '../../assets/png/controlar.png';
import { Image } from 'react-bootstrap';

const Check = ({title, description}) => {
  return (
    <div className='check'>
      <Image src={CheckImg} alt="" />
      <div className="data_info">
        <p><b className='title'>{title}</b>  {description}</p>
      </div>
    </div>
  )
}

export default Check
