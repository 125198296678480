import React from 'react';
import './ResponsiveLetter.scss';

const ResponsiveLetter = () => {
    return (
        <div className="carta-responsiva">
          <h1>Carta Responsiva General</h1>
          
          <p>
            Por medio de la presente, reconozco y acepto que al hacer uso de las instalaciones y atracciones disponibles:
          </p>
          
          <ol>
            <li>
              <strong>Mi seguridad personal, así como la de mis acompañantes (incluyendo menores bajo mi supervisión), es mi completa responsabilidad.</strong> Acepto que cualquier riesgo o daño físico que pueda ocurrir durante nuestra estancia es bajo nuestra exclusiva responsabilidad.
            </li>
            <li>
              Me comprometo a seguir todas las normas y recomendaciones de seguridad indicadas por el personal del lugar y a asegurarme de que mis acompañantes hagan lo mismo, prestando especial atención al cuidado de los menores.
            </li>
            <li>
              Declaro que <strong>la empresa que opera las instalaciones</strong> y <strong>Access Go</strong> no serán responsables por cualquier accidente, lesión, daño físico o pérdida material que ocurra durante el uso de las instalaciones o atracciones. Cualquier incidente de esta índole será bajo mi total responsabilidad.
            </li>
            <li>
              Entiendo que es mi deber supervisar el uso adecuado de las atracciones por parte de mis acompañantes, especialmente menores, y que el incumplimiento de las indicaciones de seguridad o el uso indebido de las instalaciones puede incrementar el riesgo de accidentes.
            </li>
          </ol>
    
          <h2>Aviso Importante:</h2>
          <p>
            <strong>La empresa que opera las instalaciones</strong> y <strong>Access Go</strong> proporcionan un entorno seguro dentro de sus posibilidades, pero <strong>se deslindan de toda responsabilidad en caso de que ocurran accidentes o daños derivados de un uso inadecuado de las instalaciones</strong> o por causas ajenas a su control.
          </p>
        </div>
      );
    };

export default ResponsiveLetter
